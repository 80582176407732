<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_partners">
      <div class="container">
        <div class="partners_inner">
          <ul>
            <li class="wow fadeIn" data-wow-duration="1s">
              <div class="list_inner">
                <img :src="`/web/pay/bca.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div class="list_inner">
                <img :src="`/web/provider/axis.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/bni.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img :src="`/web/provider/by.u.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s">
              <div class="list_inner">
                <img :src="`/web/pay/bri.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div class="list_inner">
                <img :src="`/web/provider/indosat.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/gopay.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img :src="`/web/provider/telkomsel.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/link.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img :src="`/web/provider/tri.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/mandiri.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img :src="`/web/provider/xl.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <!-- <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/ovo.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img :src="`/web/pay/shopee.png`" alt="" />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/partners/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
