<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_testimonials">
      <div class="dizme_tm_main_title" data-align="center">
        <span>Testimonials</span>
        <h3>Apa Yang Mereka Bilang</h3>
        <p></p>
      </div>
      <div class="list_wrapper">
        <div class="total">
          <div class="in">
            <swiper
              :pagination="{ clickable: true, el: '.owl-dots' }"
              :modules="modules"
              class="owl-carousel owl-theme"
            >
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    CSnya ramah, dibantu sampai transaksi sukses. Pulsabiz emang
                    jadi jasa convert pulsa langganan yang recommended.
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/4.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>Amirul Hadi</h3>
                    <span>Pelanggan Setia</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    Makasih kak pelayananya.. baru pertama kali nyobain nukar
                    pulsa jadi uang ternyata beneran bisa dan aman
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/3.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>Nadia Indri</h3>
                    <!-- <span>ABC Group</span> -->
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    Gak perlu ragu pokoknya kalau cv di Pulsabiz. Mau cv Cuma
                    puluhan ribu/ratusan ribu sukses terus disini. Mantap pollll
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/6.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>Prasetyo</h3>
                    <span> </span>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="owl-dots"></div>
          </div>
          <div class="left_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/web/provider/xl.png"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/web/provider/by.u.png"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/web/provider/indosat.png"
            ></div>
            <span class="circle green animPulse"></span>
            <span class="circle yellow animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
          <div class="right_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/web/provider/tri.png"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/web/provider/xl.png"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/web/provider/telkomsel.png"
            ></div>
            <span class="circle yellow animPulse"></span>
            <span class="circle purple animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/testimonials/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { testimonialIcon } from "@/svg";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "TestimonialsComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonialIcon,
    };
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>
