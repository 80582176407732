<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Kami Adalah</h3>
            </div>
            <div class="name">
              <h3>Pulsabiz</h3>
            </div>
            <div class="job">
              <p>
                <span class="greenText">Jasa</span> Untuk
                <span class="purpleText">Ubah Pulsa Jadi Uang</span>
              </p>
            </div>
            <div class="text">
              <p>
                Punya pulsa menumpuk? Tukar pulsa jadi uang ke rekening bank
                atau e-wallet hanya dengan hitungan menit melalui Pulsabiz
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#contact"
                  ><span>Tukar Sekarang</span></a
                >
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61557547996041&mibextid=ZbWKwL"
                      ><i class="icon-facebook-1"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/pulsabizdigital"
                      ><i class="icon-instagram-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/web/slider/avatar_png.png" alt="" />
              <span
                class="skills illustrator anim_moveBottom"
                v-html="ai"
              ></span>
              <span class="skills photoshop anim_moveBottom" v-html="ps"></span>
              <span class="skills figma anim_moveBottom" v-html="figma"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
