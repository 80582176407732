<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_skills">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div
              class="dizme_tm_main_title wow fadeInUp"
              data-wow-duration="1s"
              data-align="left"
            >
              <span>Service</span>
              <h3>Rate Konvert Pulsa</h3>
              <p>
                Pulsabiz memberikan rate terbaik dan stabil untuk menukarkan
                pulsamu menjadi uang melalui rekening bank atau e-wallet
              </p>
            </div>
            <div class="dodo_progress wow fadeInUp" data-wow-duration="1s">
              <div
                class="progress_inner skillsInner___"
                data-value="83"
                data-color="#f75023"
              >
                <span
                  ><span class="label">Telkomsel</span
                  ><span class="number">0.83</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="86"
                data-color="#1cbe59"
              >
                <span
                  ><span class="label">Tri</span
                  ><span class="number">0.86</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="86"
                data-color="#8067f0"
              >
                <span
                  ><span class="label">XL/Axis</span
                  ><span class="number">0.86</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="0"
                data-color="#8067f0"
              >
                <span
                  ><span class="label">Indosat</span
                  ><span class="number">0</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="0"
                data-color="#8067f0"
              >
                <span
                  ><span class="label">Smartfren</span
                  ><span class="number">0</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img :src="`/web/About_600x600.png`" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
