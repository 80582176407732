<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/web/About_2_600x600.png`" alt="" />
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="5"></VueJsCounter>
                  </h3>
                  <span class="name">Tahun<br />Pengalaman</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="9"></VueJsCounter>K
                  </h3>
                  <span class="name">Total<br />Konvert</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>Jasa Tukar dan Beli Pulsa Terpercaya di Indonesia</span>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                Ayo jadikan pulsamu menjadi uang di Pulsabiz dengan rate stabil
                dan transaksi yang mudah. Dengan customer service yang ramah dan
                professional, tidak perlu lagi repot dalam menyelesaikan
                permasalahan pulsa yang menumpuk..
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>Hubungi Kami</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
