<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Kontak Kami</span>
          <p>
            Jika anda mengalami jangan ragu untuk menghubungi kami, follow juga
            sosial media kami agar mendapat update rate harga terbaik
          </p>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul class="no-bullets">
              <li>
                <div class="list_inner">
                  <div class="icon orangeBackground">
                    <i class="icon-location orangeText"></i>
                  </div>
                  <div class="short">
                    <h3>Alamat</h3>
                    <span>Kalibedah, Balerejo, Kec. Balerejo 63152</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon greenBackground">
                    <i class="icon-mail-1 greenText"></i>
                  </div>
                  <div class="short">
                    <h3>Email</h3>
                    <span><a href="#">cs@pulsabiz.com</a></span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <a href="https://wa.me/62895801876000">
                    <div class="icon purpleBackground">
                      <i class="icon-chat purpleText"></i>
                    </div>
                  </a>
                  <div class="short">
                    <h3 class="no-underline">Whatsapp</h3>
                    <span>0895801876000</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                @submit.prevent="sendMessage"
                class="contact_form"
                id="contact_form"
                autocomplete="off"
              >
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                ></div>
                <div class="empty_notice">
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul class="no-bullets">
                    <li>
                      <input
                        v-model="name"
                        id="name"
                        type="text"
                        placeholder="Nama Anda"
                        required
                      />
                    </li>
                    <li>
                      <input
                        v-model="whatsapp"
                        id="whatsapp"
                        type="text"
                        placeholder="Whatsapp"
                        required
                      />
                    </li>
                    <li>
                      <input
                        v-model="kartu"
                        id="kartu"
                        type="text"
                        placeholder="Kartu Perdanan (telkomsel,axis dll)"
                        required
                      />
                    </li>
                    <li>
                      <input
                        v-model="nominal"
                        id="nominal"
                        type="number"
                        placeholder="Nominal Konvert"
                        required
                      />
                    </li>
                    <li>
                      <input
                        v-model="rekening"
                        id="rekening"
                        type="text"
                        placeholder="Rekening (BCA,OVO,Dana,BRI dll)"
                        required
                      />
                    </li>
                    <li>
                      <input
                        v-model="nomorRekening"
                        id="nomorRekening"
                        type="text"
                        placeholder="Nomor Rekening"
                        required
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <!-- <textarea
                    id="message"
                    placeholder="Write your message here"
                  ></textarea> -->
                </div>
                <div class="dizme_tm_button">
                  <a id="send_message" href="#" @click.prevent="sendMessage"
                    ><span>Convert</span></a
                  >
                </div>
              </form>
            </div>
          </div>
          <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
            <img src="img/brushes/contact/2.png" alt="" />
          </div>
        </div>
        <div class="dizme_tm_map wow fadeInUp" data-wow-duration="1s">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="375"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=-7.556548639081793,111.58052880045258&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              <a
                href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon"
              ></a>
            </div>
          </div>
          <!-- Get your API here https://www.embedgooglemap.net -->
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="img/brushes/contact/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactComponent",
  data() {
    return {
      name: "",
      whatsapp: "",
      kartu: "",
      nominal: "",
      rekening: "",
      nomorRekening: "",
    };
  },
  methods: {
    sendMessage() {
      const whatsappBaseUrl = "https://wa.me/62895801876000"; // ganti dengan nomor tujuan
      const message = `*Order Convert* \n\nNama: ${this.name} \nWhatsapp: ${this.whatsapp} \nKartu: ${this.kartu} \nNominal: ${this.nominal} \nRekening: ${this.rekening} \nNomor Rekening: ${this.nomorRekening}`;
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `${whatsappBaseUrl}?text=${encodedMessage}`;

      window.open(whatsappUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
